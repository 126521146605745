import React from "react";

import './InformationNav.css'




const InformationNav = props => {
    
    return (
        <section className="InformationNav__section-container" >
            <div className="InformationNav__information">
                <span> Home {">>"} {props.props} </span>
            </div>
        </section>
    );
};

export default InformationNav;