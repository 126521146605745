import React, {useState , useEffect}from "react";

import './HotItem.css';
import OfferCard from "../OfferCard/OfferCard";

import offer1 from '../../../utils/assets/home/father-and-daughter.png';
import offer2 from '../../../utils/assets/home/pexels-craig-adderley.png';
import offer3 from '../../../utils/assets/home/universal.png';
import offer4 from '../../../utils/assets/home/Most-Adventurous-things.png';

const HotItem = () => {
  const itemsInfo = [
    {
      img: offer1,
      discount: 60,
      name: "Sam's Club"
    },
    {
      img: offer2,
      discount: 25,
      name: "Disneyland Resort"
    },
    {
      img: offer3,
      discount: 30,
      name: "Universal Orlando Resort"
    },
    {
      img: offer4,
      discount: 25,
      name: "Adventure"
    }
  ];

  const itemsToShowMobile = 1;
  const itemsToShowTablet = 1;
  const itemsForTabletDevice = 2;
  const showAllItems = 3;

  const [currentImage, setCurrentImage] = useState(0);
  const [visibleItems, setVisibleItems] = useState(
    itemsInfo.slice(0, getItemsToShow())
  );

  function getItemsToShow() {
    if (window.innerWidth < 768) {
      return itemsToShowMobile;
    } else if (window.innerWidth >= 768 && window.innerWidth < 1002) {
      return itemsToShowTablet;
    } else if (window.innerWidth >= 1002 && window.innerWidth < 1500) {
      return itemsForTabletDevice;
    } 
    else{
      return showAllItems;
    }
  }

  const updateVisibleItems = () => {
    const startIndex = currentImage % itemsInfo.length;
    const endIndex = startIndex + getItemsToShow();
    const newVisibleItems =
      endIndex <= itemsInfo.length
        ? itemsInfo.slice(startIndex, endIndex)
        : [...itemsInfo.slice(startIndex), ...itemsInfo.slice(0, endIndex % itemsInfo.length)];
    setVisibleItems(newVisibleItems);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % itemsInfo.length);
    updateVisibleItems();
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + itemsInfo.length) % itemsInfo.length);
    updateVisibleItems();
  };

  useEffect(() => {
    updateVisibleItems();
  }, [currentImage]);

  useEffect(() => {
    const handleResize = () => {
      updateVisibleItems();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="HotItem__main-container">
      <h2 className="text-center HotItem__main-title">Hot items</h2>
      <div className="HotItem__carousel-container">
        <div className="HotItem__carousel">
          <div className="HotItem__prev-btn-container">
            <button className="HotItem__prev-btn" onClick={prevImage}>
              &#10094;
            </button>
          </div>
          <div className="HotItem__carousel-main">
            {visibleItems.map((item, index) => (
              <OfferCard
                className={`carousel-slide ${
                  index === currentImage % getItemsToShow() ? 'active' : ''
                }`}
                onClick={() => setCurrentImage(index)}
                key={index}
                item={item}
              ></OfferCard>
            ))}
          </div>
          <div className="HotItem__next-btn-container">
            <button className="HotItem__next-btn" onClick={nextImage}>
              &#10095;
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HotItem;