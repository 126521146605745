import React from "react";


import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";

import whatWeOffer from '../../utils/assets/price/See-what-we-offer-and-try-now.png';

import Subscription from "../components/Subscription/Subscription";

import InformationNav from "../../shared/components/InformationNav/InformationNav";

import seeWhatWeOfferMobileImg from '../../utils/assets/price/mobile/see-what-we-offer.png';

import seeWhatWeOfferTabletImg from '../../utils/assets/price/tablet/see-what.png';


const Price = props => {
    const legendInfo =
    {
        cardTitle: "See what we offer",
        cardBodyText: `Start enjoying countless benefits and unlimited offers. Get your membership today.`,
        cardButtonText: "Try Now",
        lengendBackground: whatWeOffer,
        mobileImg : seeWhatWeOfferMobileImg,
        tabletImg : seeWhatWeOfferTabletImg
    }

    return (
        <React.Fragment>
            {/* <h2>I am a AboutUs</h2> */}
            <SectionInfo
                props={legendInfo}
            />
            <InformationNav props={"Price"}></InformationNav>
            <Subscription></Subscription>
            
        </React.Fragment>
    );
};

export default Price;