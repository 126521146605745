import React from "react";

import refundPolicyLegen from '../../utils/assets/refundPolicy/return-policy.png';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";

import ReimbursementGuidelines from '../components/ReimbursementGuidelines/ReimbursementGuidelines';
import InformationNav from "../../shared/components/InformationNav/InformationNav";




const RefundPolicy = props => {
    const legendInfo =
    {
        cardTitle: "Problems with an item?",
        cardBodyText: `If you are returning a product due to an error or it is not what you wanted, follow the steps to request your refund. It is a quick and easy process!`,
        cardButtonText: "Call us",
        lengendBackground: refundPolicyLegen
    }

    return (
        <React.Fragment>
         
            <SectionInfo
                props={legendInfo}
            />
            <InformationNav props={"Refund policy"}></InformationNav>
            
            <ReimbursementGuidelines />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;