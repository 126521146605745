import React from "react";



import './Benefits.css';
import BenefitsList from './components/BenefitsList/BenefitsList';

const Benefits = props => {


    return (
        <>
        <section className="Benefits__title-section-container">
            <h1>Benefits</h1>
        </section>
        <BenefitsList></BenefitsList>
        </>
    );
};

export default Benefits;