import React from "react";

import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import HotItem from '../components/HotItem/HotItem';
import JoinOurCommunity from '../components/JoinOurCommunity/JoinOurCommunity';


import shopSmarterImg from '../../utils/assets/home/enroll-now-to-club-save.png';
import aboutClub from '../../utils/assets/home/about-Us.png';

import enrollNowMobileImg from '../../utils/assets/home/mobile/enroll-now.png';
import knowMoreMobileImg from '../../utils/assets/home/mobile/know-more.png';

import enrollNowTabletImg from '../../utils/assets/home/tablet/enroll-now.png';
import knowMoreTabletImg from '../../utils/assets/home/tablet/know-more.png';




const Home = props => {

    const legendInfo = [
        {
            cardTitle : "Shop Smart, Save Smarter!",
            cardButtonText : "register",
            cardBannerPosition : 1,
            lengendBackground : shopSmarterImg,
            mobileImg : enrollNowMobileImg,
            tabletImg : enrollNowTabletImg
            
        },
        {
        
            cardTitle : "Save money on your daily expenses",
            cardBannerPosition: 2,
            cardButtonText : "know more",
            lengendBackground : aboutClub,
            mobileImg : knowMoreMobileImg,
            tabletImg : knowMoreTabletImg
        
    }

    ]

    return(
        <React.Fragment>
            <SectionInfo id="home" props={legendInfo[0]}/>
            <HotItem></HotItem>
            <SectionInfo props={legendInfo[1]}/>
            <JoinOurCommunity></JoinOurCommunity>
        </React.Fragment>
    );
};

export default Home;