import React, { useState } from "react";

import './Faq.css';

import Accordion from './components/Accordion/Accordion';
import CustomerService from '../../../shared/components/CustomerService/CustomerService';



const Faq = props => {

    const faqsInformation =
        [
            {
                question: "How do I log in to my account?",
                answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
                open: false
            },
            {
                question: "How much does it cost to use the service?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "Where are my billing details ?",
                answer: "This many!",
                open: false
            },
            {
                question: "How do I redeem my rewards?",
                answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
                open: false
            },
            {
                question: "What service does Clubsave offer?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "How do I cancel?",
                answer: "This many!",
                open: false
            }
        ]

    const [faqs, setFaqs] = useState(faqsInformation)

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
  
        <section className="Faq__main-container">
            {/* <h1 className="Faq__main-container__main-title">FAQ</h1>
            <div className="Faq__faqs-container">   
                <div className="Faq__faqs columns faqs">
                    {faqs.map((faq, index) => (
                        <Accordion faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}></Accordion>
                    ))}
                </div>
            </div> */}
             <div className="Faq__customer-service-container">
                <CustomerService></CustomerService>
             </div>
        </section>

    );
};

export default Faq;