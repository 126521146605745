import React from "react";


import './ContactInformation.css'

import emailIcon from '../../../utils/assets/icons/email-icon.png';
import personIcon from '../../../utils/assets/icons/customer-service-icon.png';

const ContactInformation = ({props}) => {
    let contactInformation;

    switch( window.location.pathname ){
        case '/contact-us':
                contactInformation = (
                    <p className="ContactInformation__description">
                        <b>Fill out the contact form with your
                        request and we will get back to you as 
                        soon as possible. 
                        </b>
                        You can also contact us in the following
                        ways:
                        Send us an email or call us now and we will
                        be happy to answer any questions you
                        may have.
                    </p>
                )
            break;

        case '/cancel-service':
            contactInformation = (
                <p className="ContactInformation__description">
                    <b>Please fill out the Order ID form and we
                    will contact you as soon as possible.</b> You
                    can also contact us in the following ways:
                    Send us an email or call us now and we will
                    be happy to answer any questions you
                    may have. 
                </p>
            )
            break;

        default:
            contactInformation = null;
            break;
    }

    return (
        <div className="ContactInformation__main-container">
            <div className="ContactInformation__title-container">
                <h1 className="ContactInformation__title">
                    {props.contactInformationTitle}
                </h1>
            </div>

            <div className="ContactInformation__description-container">
                {contactInformation}
            </div>

            <div className="ContactInformation__contact-container">
                
                <div className="ContactInformation__contact-email-container">
                    <div className="ContactInformation__email-icon-container">
                        <img src={emailIcon} alt="" className="ContactInformation__email-icon"/>
                    </div>
                    <div className="ContactInformation__email-container">
                        <p className="ContactInformation__email">
                        help@clubsavellc.com
                        </p>
                    </div>
                </div>

                <div className="ContactInformation__contact-number-container">
                    <div className="ContactInformation__person-icon-container">
                        <img src={personIcon} alt="" className="ContactInformation__person-icon"/>
                    </div>
                    <div className="ContactInformation__number-container">
                        <p className="ContactInformation__number">
                        (833) 851-1369
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="ContactInformation__business-days-container">
                <p className="ContactInformation__business-days">
                    {props.contactInformationWeek} <br></br>
                    {props.contactInformationWeekend} <br></br>
                </p>
            </div>
        </div>
    );
};

export default ContactInformation;