import React from "react";

import './BenefitsList.css';


import carIcon from '../../../../../utils/assets/aboutUs/icons/car-icon.png';
import foodIcon from '../../../../../utils/assets/aboutUs/icons/food-icon.png';
import parkIcon from '../../../../../utils/assets/aboutUs/icons/park-icon.png';
import shoppingIcon from '../../../../../utils/assets/aboutUs/icons/shopping-icon.png';
import spaIcon from '../../../../../utils/assets/aboutUs/icons/spa-icon.png';
import travelIcon from '../../../../../utils/assets/aboutUs/icons/travel-icon.png';



const BenefitsList = props => {


    return (
        <div className="BenefitsList__main-container">
            <div className="BenefitsList__list-container">
                <div className="BenefitsList__first-benefits">


                    
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={carIcon} alt="vet247Icon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Need to move?</h6>
                            <p className="BenefitsList__description">
                            Discounts on car rentals, gas, repairs and
                            auto parts at a fraction of the price.
                            </p>
                        </div>
                    </div>

                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={foodIcon} alt="doubtsIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Enjoy discounts on all your meals</h6>
                            <p className="BenefitsList__description">
                            Discover elegant restaurants, new 
                            snacks, unique offers at supermarkets 
                            and more.
                            </p>
                        </div>
                    </div>


                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={parkIcon} alt="guidanceIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Take the whole family to the best theme parks </h6>
                            <p className="BenefitsList__description">
                            Make your reservations through us
                            and enjoy exclusive discounts on tickets
                            and attractions.   
                            </p>
                        </div>
                    </div>
                    




                </div>




                <div className="BenefitsList__second-benefits">
                    

                <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={shoppingIcon} alt="emergencyIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Shop at the best stores</h6>
                            <p className="BenefitsList__description">
                            Find the best deals at places like Macy's,
                            Best Buy, Game Stop, and more. Save
                            while you enjoy what you love.
                            </p>
                        </div>     
                    </div>





                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={spaIcon} alt="drugsIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Experience the power of wellness. </h6>
                            <p className="BenefitsList__description">
                            Access more than 9,000 spa locations. 
                            Enjoy massages, facials, yoga, Pilates, 
                            fitness classes and more.
                            </p>
                        </div>
                    </div>



                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={travelIcon} alt="learnIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Travel and see more of the world</h6>
                            <p className="BenefitsList__description">
                            Spend less on every part of your trip with
                            access to exclusive private discounts.
                            </p>
                        </div>
                    </div>



                    
                </div>
            </div>
        </div>
    );
    
};

export default BenefitsList;