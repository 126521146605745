import localStorageService from "../services/localStorageService"
import { IDENTITY_SERVICES_PRODUCTS } from "../constants/localStorageKeys"
const haveAccess = ( ) =>{

    const localValue = localStorageService.getProduct( IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON );

    if ( localValue !== null ) return true;

    else return false;
    

}

export default haveAccess;