import React, { useEffect } from "react";

import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Benefits from '../components/Benefits/Benefits';
import Faq from '../components/Faq/Faq';
import InformationNav from "../../shared/components/InformationNav/InformationNav";

import aboutUsLegendImgBackground from '../../utils/assets/aboutUs/who-are-we.png';
import whoAreWeMobileImg from '../../utils/assets/aboutUs/mobile/who-are-we.png';
import whoAreWeTabletImg from '../../utils/assets/aboutUs/tablet/who-are-we.png'


const AboutUs = props => {
    const legendInfo = 
        {
            cardTitle : "Who are we?",
            cardButtonText : "Join Us",
            lengendBackground : aboutUsLegendImgBackground,
            mobileImg : whoAreWeMobileImg,
            tabletImg : whoAreWeTabletImg
        }
          
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return(
        <React.Fragment>
            <SectionInfo 
            props={legendInfo}
            />
            
           <InformationNav props={"About us"}></InformationNav>
            <HowItWorks></HowItWorks>
            <Benefits></Benefits>
            <Faq></Faq>
        </React.Fragment>
    );
};

export default AboutUs;