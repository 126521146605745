import React from "react"
import { Link } from 'react-router-dom';


import './OfferCard.css';
import offerIcon from '../../../utils/assets/home/icon/offer-icon.png';

const OfferCard = ( { item }) => {

    return (


        <div id="component" className="OfferCard__main-card-container">

            <div className="OfferCard__inner-card-container">
                <div className="OfferCard__card-img">
                    <img className="OfferCard__img" src={item.img} />
                </div>
                <div className="OfferCard__offer-information">
                   <div className="OfferCard__offer">
                   <div className="OfferCard__offer-value">
                        <p>
                            {`${item.discount}% Off`}
                        </p>
                    </div>
                    <div className="OfferCard__offer-icon">
                        <img className="OfferCard__icon" src={offerIcon} alt="Offer-icon" />
                    </div>
                   </div>
                </div>
                <div className="OfferCard__trial-container">
                    <p>
                        {item.name}
                    </p>
                </div>
                <div className="OfferCard__button-container">
                    <div className="OfferCard__button">
                        <Link to="/about-us">
                        <button>View Deal</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>



    );
};

export default OfferCard;