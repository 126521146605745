import React, { useState} from 'react';
import { NavLink } from 'react-router-dom';


import './NavLinks.css';
import CallUsButton from '../UIElements/CallUsButton';

const NavLinks = props => {

  let styles = {
    borderBottom: '3px solid #f8df00',
  };

const [activeLink, setActiveLink] = useState(`${sessionStorage.getItem('sectionName') === 'home' ? 'home' : sessionStorage.getItem('sectionName') === 'price' ? 'price'  : ''  }`);
  const handleHomeClick = () => {
    setActiveLink('');
    window.scrollTo(0, 0);
    sessionStorage.clear();
  };

  return (
    <React.Fragment>
    <ul className="nav-links" >
      <li>
        <NavLink to="/"  onClick={handleHomeClick} style={activeLink === 'home' ? styles : {}} exact>HOME</NavLink>
      </li>

      <li>
        <NavLink to="/about-us" onClick={handleHomeClick}>ABOUT US</NavLink>
      </li>

      <li>
        <NavLink to="/price" onClick={handleHomeClick} style={activeLink === 'price' ? styles : {}}>PRICE</NavLink>
      </li>

      <li>
        <NavLink to="/contact-us" onClick={handleHomeClick}>CONTACT</NavLink>
      </li>

      <li>
        <NavLink to="/cancel-service" onClick={handleHomeClick}>CANCEL</NavLink>
      </li>


      <li>
        <a href='https://vip.enjoymydeals.com/' target='_blank'>LOGIN</a>
      </li>

       <li className='NavLinks__call-us-button-link' >
        <CallUsButton
        phonenumber="CALL NOW"
        />
      </li> 
    </ul>
   

    </React.Fragment>
  );
};

export default NavLinks;
