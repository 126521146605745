import React from 'react';

import checkoutLegend from '../../utils/assets/checkout/checkout.png';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import InformationNav from '../../shared/components/InformationNav/InformationNav';
import BecomeMember from '../components/BecomeMember/BecomeMember';

import checkoutMobileImg from '../../utils/assets/checkout/mobile/checkout.png';


const Checkout = () => {

    const legendInfo =
    {
        cardTitle: "It's good you're here",
        cardBodyText: `Thank you for joining our exclusive membership club. Enjoy premium shopping, travel deals, amazing spa days and more.`,
        cardButtonText: "",
        lengendBackground: checkoutLegend,
        mobileImg : checkoutMobileImg
    }

    return (
        <React.Fragment>
           
            <SectionInfo
                props={legendInfo}
            />
           <InformationNav props={"Checkout"}></InformationNav>
            <BecomeMember/>

            
        </React.Fragment>
    );
};

export default Checkout;
