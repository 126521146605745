import React, { useEffect } from "react";

import cancelServiceLegen from '../../utils/assets/cancelService/Contact-us-and-enroll-now.png';
import Cancelation from '../components/Cancelation/Cancelation';

import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import InformationNav from "../../shared/components/InformationNav/InformationNav";

const CancelService = props => {
    const legendInfo =
    {
        cardTitle: "Want to unsubscribe?",
        cardBodyText: `You can cancel quickly and easily by filling out the cancellation form or calling us. We will get back to you as soon as possible!`,
        cardButtonText: "",
        lengendBackground: cancelServiceLegen
    }

    useEffect(()=>{
        window.scroll(0,0);
    })

    return (
        <React.Fragment>
            
            <SectionInfo
                props={legendInfo}
            />
            <InformationNav props={"Cancel service"}></InformationNav>
            <Cancelation></Cancelation>
            
        </React.Fragment>
    );
};

export default CancelService;