import React from "react";

import './CustomerService.css';



import customerServiceIcon from '../../../utils/assets/icons/customer-service-icon.png';
import emailServiceIcon from '../../../utils/assets/icons/email-icon.png';




const CustomerService = () => {

    return (


        <div className="CustomerService__information-container">
            <div className="CustomerService__contact-information">
                <div className="CustomerService__service-number-contact-container">
                    <div className="CustomerService__person-support-icon-container">
                        <img src={customerServiceIcon} alt="Customer Service Icon"/>
                    </div>
                    <div className="CustomerService__service-number-container">
                    <p>
                        (833) 851-1369
                    </p>
                    </div>
                </div>

                <div className="CustomerService__email-contact-container">
                    <div className="CustomerService__email-icon-container">
                        <img src={emailServiceIcon} alt="Email Service Icon"/>
                    </div>
                    <div className="CustomerService__email-container">
                        <p>
                        help@clubsavellc.com
                        </p>
                    </div>
                </div>

            </div>
            <div className="CustomerService__description-information">
                <p>
                If you can't remember your login 
                information or need assistance with  
                billing issues. <b>Please contact us!</b>
                </p>
            </div>
        </div>


    );
};

export default CustomerService;