import React from "react";

import serviceTermsLegen from '../../utils/assets/termsOfService/Terms-of-service-club-save.png';

import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";

import MembershipTerms from "../components/MembershipTerms/MembershipTerms";

import InformationNav from '../../shared/components/InformationNav/InformationNav';



const TermsOfService = props => {
    const legendInfo =
    {
        cardTitle: "Service terms",
        cardBodyText: `Find out everything you need to know about membership services. If you have any questions or concerns, please contact us!`,
        cardButtonText: "Make a Call",
        lengendBackground: serviceTermsLegen
    }

    return (
        <React.Fragment>
            <SectionInfo
                props={legendInfo}
            />
             <InformationNav props={"Terms of service"}></InformationNav>
            <MembershipTerms></MembershipTerms>
        </React.Fragment>
    );
};

export default TermsOfService;