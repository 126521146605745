import React, { useEffect } from "react";

import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import ContactUs from '../components/ContactUs/ContactUs';
import wantToJoin from '../../utils/assets/contact/Contact-us-and-enroll-now.png';
import InformationNav from "../../shared/components/InformationNav/InformationNav";


const Contact = props => {
    const legendInfo =
    {
        cardTitle: "Want to join?",
        cardBodyText: `Save thousands of dollars a year by choosing to be part of our community. So what are you waiting for, you are just a phone call away!`,
        cardButtonText: "",
        lengendBackground: wantToJoin
    }

    useEffect(()=>{
        window.scroll(0,0);
    },[])

    return (
        <React.Fragment>

            <SectionInfo
                props={legendInfo}
            />
            <InformationNav props={"Contact"}></InformationNav>
            <ContactUs/>
            

            
        </React.Fragment>
    );
};

export default Contact;