import validate from "../validators/dataValidation";


const error = {
    validateField :  ( fieldName, fieldValue )=>{

      switch (fieldName) {
        case 'cardNumber':
          return validate.cardNumber( fieldValue );
      
        case 'fcvv':
          return validate.cvv(fieldValue);
    
        case 'expDate':
          return validate.hasCardValidExpirationDate(fieldValue);

        case 'firstName':
        case 'lastName':
            return validate.nameOrLastname( fieldValue, fieldName );
            
        case 'city': 
          return validate.isEmptyField( fieldValue, fieldName );

        case 'zipCode':
            return validate.isEmptyField( fieldValue, fieldName );

        case 'email':
            return validate.emailAddress( fieldValue );            
        case 'state':
            return validate.isEmptyField( fieldValue, fieldName );
        
        case 'address':
        case 'addressApt': 
            return validate.isEmptyField( fieldValue, fieldName );
        
        case 'phoneNumber':
            return validate.phoneNumber( fieldValue );

        case 'message':
          return validate.isEmptyField( fieldValue, fieldName );

        case 'orderId':
        case 'reasonForCancellation':
          return validate.isEmptyField( fieldValue, fieldName );
        
        default:
          return true; 
      }
    },
    getErrorMessage : (fieldName) => {
       
        switch (fieldName) {
      
          case 'cardNumber':
            return 'Please enter a valid card number';
      
          case 'fcvv':
            return 'Please enter a valid cvv';
      
          case 'expDate':
            return 'Please enter a valid expDate format(mm-yy)';
        
          case 'firstName':
            return 'Please enter a valid name';
        
        case 'city':
            return 'Please enter a valid city';

        case 'zipCode':
            return 'Please enter a valid zip code';
        
        case 'email':
            return 'Please enter a valid email';
        
        case 'lastName':
            return 'Please enter a valid last name';
        
        case 'state':
            return 'Please select a valid state';
        
        case 'address':
            return 'Please enter a valid address';

        case 'phoneNumber':
            return 'Please enter a valid phone number';

        case 'message':
          return 'Please enter a valid message';

        case 'orderId':
          return 'Please enter a valid order id';

        case 'reasonForCancellation':
          return 'Please select a valid reason for cancellation'
      
          default:
            return 'Validation error';
        }
    }
}
export default error;

