import React from "react";

import './ContactUs.css'

import ContactInformation from '../../../shared/components/ContactInformation/ContactInformation';
import ContactForm from '../ContactForm/ContactForm';


const ContactUs = props => {

    const contactInformation ={
        contactInformationTitle : "Feel free to contact us",
        contactInformationBody : "Fill out the contact form with your request and we will get back to you as soon as possible. You can also contact us in the following ways: Send us an email or call us now and we will be happy to answer any questions you may have. ",
        contactInformationWeek : "Monday - Friday 9:00 AM - 09:00 PM EST",
        contactInformationWeekend : "Saturday - Sunday 9:00 AM - 05:00 PM EST"
    }

    return (
        <section className="ContactUs__main-container">
                <div className="ContactUs__contact-information-container">
                    <div className="ContactUs__contact-information">
                        <ContactInformation props={contactInformation}></ContactInformation>
                    </div>
                </div>
                <div className="ContactUs__contact-form-container">
                    <div className="ContactUs__contact-form">
                        <ContactForm></ContactForm>
                    </div>
                </div>  
        </section>
    );
};

export default ContactUs;