import React from 'react';

import './BecomeMember.css'
import PaymentForm from '../PaymentForm/PaymentForm';


const BecomeMember = () => {
    return (
        <section className='BecomeMember__main-section'>
            <div className='BecomeMember__main-title-container'>
                <h1 className="BecomeMember__title">Become a member today</h1>
            </div>
            <div className='BecomeMember__payment-form-container'>
                <PaymentForm></PaymentForm>
            </div>
        </section>
    );
};

export default BecomeMember;
