import React from "react";


import './MainFooter.css'

import clubSaveIcon from '../../assets/FooterAssets/main-logo.png';
import customerSeviceIcon from '../../../utils/assets/icons/customer-service-icon.png';
import emailIcon from '../../../utils/assets/icons/email-icon.png';
import creditCardIcon from '../../assets/FooterAssets/credit-cards.png';

const MainFooter = props => {
    const handleClick = ( sectionId ) => {
        sessionStorage.setItem('sectionName', sectionId);
        window.scroll(0, 0);
      };

      const handleGoTo = () =>{
        sessionStorage.setItem('sectionName', 'home');
        window.location.href = '/cancel-service';
      }
      
    return (
        <footer className="MainFooter__footer">

            <div className="MainFooter__main-footer-container">
                <div className="MainFooter__left-container" >
                    <div className="MainFooter__left-logo-container">
                        <img src={clubSaveIcon} alt="clubSaveIcon" className="MainFooter__logo" />
                    </div>
                    <div className="MainFooter__left-contact-container" >
                        <img src={customerSeviceIcon} alt="customerSeviceIcon" className="MainFooter__icons" />
                        <p>(833) 851-1369</p>
                    </div>
                    <div className="MainFooter__left-email-container">
                        <img src={emailIcon} alt="emailIcon" className="MainFooter__icons" />
                        <p>
                        help@clubsavellc.com
                        </p>
                    </div>
                    <div>
                    <p className="MainFooter__business-address"> 
                    2655 Ulmerton Rd STE 121 Clearwater, FL 33762.
                    </p>
                    </div>
                </div>

                <div className="MainFooter__menus-container">
                    <div className="MainFooter__center-container" >
                        <div className="MainFooter__center-menu-container">
                            <nav className="MainFooter__center-nav">
                                <ul>
                                    <li><a href="privacy-policy" onClick={() => handleClick('home')}>PRIVACY POLICY</a></li>
                                    <li><a href="refund-policy" onClick={() => handleClick('home')}>REFUND POLICY</a></li>
                                    <li><a href="terms-of-service" onClick={() => handleClick('home')}>TERMS OF SERVICE</a></li>
                                    <li><a href="cancel-service" onClick={() => handleClick('home')}>CANCEL SERVICE</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                   {/*  <div className="MainContainer__right-separator"></div> */}
                    <div className="MainFooter__right-container" >
                        <div className="MainFooter__right-menu-container">
                            <nav className="MainFooter__right-nav">
                                <ul >
                                    <li><a href="/" onClick={() => handleClick('home')}>HOME</a></li>
                                    <li><a href="about-us" onClick={() => handleClick('about-us')}>ABOUT US</a></li>
                                    <li><a href="price" onClick={() => handleClick('price')}>PRICE</a></li>
                                    <li><a href="contact-us" onClick={() => handleClick('contact-us')}>CONTACT</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            
            
            <div className="MainFooter__main-legal-description-container">
                <div className="MainFooter__legal-description-container">
                    <div className="MainFooter__legal-description">
                    <p>
                    Member, you will receive a text or email notification 48 hours prior to your 
                    monthly billing date. If you do not wish to proceed with your enrollment, you may call our 
                    Customer Service Department at (833) 851-1369 or click <a href= '/cancel-service' onClick={handleGoTo}>here</a> to cancel and submit your request.
                    </p>
                    </div>
                </div>
                <div className="MainFooter__right-and-cards-icon-container">
                    <div className="MainFooter__copy-right-container">
                        <p>
                            <strong>Club Save © 2024 All Rights Reserved</strong>
                        </p>
                    </div>
                    <div className="MainFooter__card-icon-container">
                        <div className="MainFooter__credit-card-icons">
                            <img src={creditCardIcon} alt="creditCardIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default MainFooter;