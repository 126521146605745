import React, {useState, useEffect} from "react";
import { useHistory, useLocation } from 'react-router-dom';

import './SectionInfo.css';

const SectionInfo = ({ props }) => {
    const [componentToShow, setComponentToShow] = useState(window.innerWidth <= 767.98 ? 'mobile' : window.innerWidth >= 768 && window.innerWidth <=991.98 ? 'tablet' : 'main');



    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            if (windowWidth <= 767.98) {
                setComponentToShow('mobile');
            } else if (windowWidth >= 768 && windowWidth <= 991.98) {
                setComponentToShow('tablet');
            } 
            else{
                setComponentToShow('main');
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });


    const renderComponent = () => {
        switch (componentToShow) {
            case 'mobile':
                return (
                    <section className="SectionInfo__mobile-section-container">
                        <Mobile props={props}/>
                    </section>
                );
            case 'tablet':
                return (
                    <section className="SectionInfo__tablet-section-container">
                      <Tablet props={props}/>
                    </section>
                );
            case 'main':
            default:
                return (
                    <Desktop props={props}/>
                );
        }
    };

    return renderComponent()
};


const Desktop = ({ props }) => {
    
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
        case '/checkout':
        case '/about-us':
        case '/price':

        bannerCardInformation = (
           
            <div className="SectionInfo__main-section-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                <CardInfo props={props}></CardInfo>
            </div>
           
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-desktop__join-title">Want to join?</h1>
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-desktop__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-desktop__join-title"> Problems with an item?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-desktop__join-title"> Service terms</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-desktop__join-title"> Learn how we protect your information</h1>
                </div>
            )
            break;

        

        default:
            bannerCardInformation = (
                <div className="SectionInfo-desktop__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title">No need to worry</h1>
                </div>
            )
            break;

    }


  return (
    <>
    {bannerCardInformation}
    </>
     
   
  );
}

const Mobile = ({ props }) => {
    
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
        case '/checkout':
        case '/about-us':
        case '/price':

        bannerCardInformation = (
            <>
            <div className="SectionInfo-mobile__img-container">

                <img src={props.mobileImg} alt="banner img" className="SectionInfo-mobile__img" />
               
            </div>
            <div className="SectionInfo-mobile__card-info-component-container">
                <CardInfo props={props}></CardInfo>
            </div>
            </>
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title">Want to join?</h1>
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title"> Problems with an item?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title"> Service terms</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title"> Learn how we protect your information</h1>
                </div>
            )
            break;

        

        default:
            bannerCardInformation = (
                <div className="SectionInfo-mobile__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-mobile__join-title">No need to worry</h1>
                </div>
            )
            break;

    }


  return (
    <section className="SectionInfo-mobile__main-section-container">
        {bannerCardInformation}
    </section>
  );
}

const Tablet = ({props})=>{
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
        case '/checkout':
        case '/about-us':
        case '/price':

        bannerCardInformation = (
            <>
            <div className="SectionInfo-tablet__img-container">
                <img src={props.tabletImg} alt="banner img" className="SectionInfo-tablet__img" />
            </div>
            <div className="SectionInfo-tablet__card-info-component-container">
                <CardInfo props={props}></CardInfo>
            </div>
            </>
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title">Want to join?</h1>
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title"> Problems with an item?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title"> Service terms</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title"> Learn how we protect your information</h1>
                </div>
            )
            break;

        

        default:
            bannerCardInformation = (
                <div className="SectionInfo-tablet__img-container" style={{ backgroundColor: '#ebd467' }}>
                    <h1 className="SectionInfo-tablet__join-title">No need to worry</h1>
                </div>
            )
            break;

    }


  return (
    <section className="SectionInfo-tablet__main-section-container">
        {bannerCardInformation}
    </section>
  );
}


const CardInfo = ({ props }) => {
    const history = useHistory();
    const phoneNumber = "8338511369";
    let cardBodyText;
    const goToHandle = (e) => {
        e.preventDefault();

        switch( window.location.pathname ){
            case '/privacy-policy':
            case '/refund-policy':
            case '/terms-of-service':
                window.location.href = `tel:${phoneNumber}`;
            break;

            case '/':
            case '/about-us':
            case '/price':
                history.push('/contact-us');
                break;
          

            default:
                break;
        }

        
    }


    switch( window.location.pathname ){
        case '/':
            if( props.cardBannerPosition === 1){
                cardBodyText = (
                    <p>
                        Access <span className="CardInfo__important-text">exclusive discounts with our online
                        membership.</span> Find over 300,000 brands with
                        unique offers just for you.
                    </p>
                )
            }
            else{
                cardBodyText = (
                    <p>
                        You can save on electronics, groceries, health and
                        beauty, pet care, sports, apparel, travel, and almost&nbsp;
                        <span className="CardInfo__important-text">anything else you can think of.</span> 
                    </p> 
                )
            }
            break; 

        case '/about-us':
            cardBodyText = (
                <p>
                    Club Save is a subscription membership that helps
                    you save money on your everyday expenses and
                    more. <span className="CardInfo__important-text">We are masters of smart saving.</span> 
                </p>
            ) 
            break;

        case '/price':
            cardBodyText = (
                <p>
                    Start enjoying countless benefits and unlimited
                    offers. <span className="CardInfo__important-text">Get your membership today!</span> 
                </p>
            ) 
            break;
            case '/contact-us':
                cardBodyText = (
                    <p>
                        Save thousands of dollars a year by choosing to be 
                        part of our community. So what are you waiting for,&nbsp;
                        <span className="CardInfo__important-text">you are just a phone call away!</span> 
                    </p>
                ) 
                break;
            case '/cancel-service':
                cardBodyText = (
                    <p>
                       You can cancel quickly and easily by fill ing out the
                       cancellation form or calling us. <span className="CardInfo__important-text">We will get back to
                       you as soon as possible!
                       </span> 
                    </p>
                ) 
                break;
            
            case '/terms-of-service':
                cardBodyText = (
                    <p>
                       Find out everything you need to know about
                       membership services. <span className="CardInfo__important-text">If you have any questions or
                       concerns, please contact us! 
                       </span> 
                    </p>
                )
                break;
            
            case '/refund-policy':
                cardBodyText = (
                    <p>
                       If you are returning a product due to an error or it is
                       not what you wanted, follow the steps to request 
                       your refund. <span className="CardInfo__important-text">It is a quick and easy process!
                       </span> 
                    </p>
                )
                break;
            
            case '/privacy-policy':
                cardBodyText = (
                    <p>
                       At Club Save, <span className="CardInfo__important-text">we take the protection of your
                       personal information very seriously. 
                       </span> 
                    </p>
                )               
                break;

            case '/checkout':
                cardBodyText = (
                    <p>
                      Thank you for joining our exclusive membership 
                      club. <span className="CardInfo__important-text">Enjoy premium shopping, travel deals, 
                      amazing spa days and more.</span> 
                    </p>
                )
                break;


            default: 
            cardBodyText = (
                <p>
                  
                  <span className="CardInfo__important-text">It's simply a mistake!</span> 
                  What you are looking for may have been moved to
                  another location, or may not be a part of the site.
                  <span className="CardInfo__important-text">Here are some links that may help you.</span> 
                </p>
            )          
                break;
    }




    return (

        <div className="SectionInfo__main-card-info-container">

            <div className="SectionInfo__main-title-container">
                <h1 className="text-left">{props.cardTitle}</h1>
            </div>
            <div className="SectionInfo__main-body-container">
                {cardBodyText}
            </div>
            {
                props.cardButtonText.trim() !== "" && (
                    <div className="SectionInfo__main-button-container">
                        
                            <a href="" onClick={goToHandle} className="SectionInfo__button">
                            <div className="SectionInfo__inner-button-container">
                                {props.cardButtonText}
                                </div>
                            </a>
                        
                    </div>
                ) 
            }
        </div>

    )
}

export default SectionInfo;