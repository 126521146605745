import React from "react";


import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import Error404 from "../components/Error404/Error404";
const NotFound = () =>{

    const bannerSectionInfo =
    {
       cardTitle: "Nothing to worry about",
       cardButtonText: "",
       lengendBackground: ""
    }

    return (
        <React.Fragment>
            <SectionInfo props={bannerSectionInfo}/>
            <Error404/>

        </React.Fragment>
    );
}

export default NotFound;