import React from "react";

import './HowItWorks.css';


import familyShoppingImg from '../../../utils/assets/aboutUs/family-shopping.png';

const HowItWorks = props => {

    return (
        <div className="HowItWorks__main-container">
            <div className="HowItWorks__explanation-container">
                <div className="HowItWorks__title-container">
                    <h1>How does it work?</h1>
                </div>
                <div className="HowItWorks__explanation-container">
                    <p>
                        <b>Club Save is linked to an exclusive discounts page called My Deals.</b><br/>
                        To access your membership, go to <strong>www.vip.enjoymydeals.com</strong> and enter your Member ID. You will then be able to register. 
                        Enjoy tons of special offers and unique discounts.
                    </p>
                    <p>
                        Access your exclusive discounts from your <b>mobile phone by 
                        downloading our app, available for Apple or Android</b> and get 
                        notified when special offers and discounts become available at your favorite stores.
                    </p>
                </div>
            </div>

            <div className="HowItWorks__img-container">
                <div className="HowItWorks__img-inner-container">
                    <img src={familyShoppingImg} alt="familyShoppingImg" className="HowItWorks__img" />
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;