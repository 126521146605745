import React, {useState} from "react";

import './JoinOurCommunity.css';


import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import CustomerService  from '../../../shared/components/CustomerService/CustomerService';
import AssociatedBrand from '../../components/AssociatedBrand/AssociatedBrand';


const JoinOurCommunity = () => {

    const subscriptionCardInformation = [
    {
        subscriptionType : "try",
        subscriptionPrice: "3.49",
        subscriptionDescription: "Trial period of 21 days",
        subscriptionTrialInfo: "",
        subscriptionTrialPrice : "",
        subscriptionButtonText: "Try Now"
    },
    {
        subscriptionType : "month",
        subscriptionPrice: "41.27",
        subscriptionTrialInfo: "",
        subscriptionTrialPrice : "",
        subscriptionDescription: "Monthly",
        subscriptionButtonText: "Buy"
    }
    ]

    

    return (


        <main id="joinOurCommunity" className="mt-4 JoinOurCommunity__main-container">
            <div>
            <h1 className="center joinOurCommunity__main-title pt-4 pb-5">Join our community</h1>
            <section className="JoinOurCommunity__subscription-card-container">
                    {
                        subscriptionCardInformation.map((subscriptionCard, index) => (
                            <SubscriptionCard key={index} props={ subscriptionCard } />
                        ))
                    }
            </section>

            <section className="JoinOurCommunity__customer-support-container">
                <CustomerService></CustomerService>
            </section>

            <section className="JoinOurCommunity__associated-brands-container">
                <AssociatedBrand></AssociatedBrand>
            </section>
            </div>
        </main>



    );
};

export default JoinOurCommunity;