import React from "react";


import './Subscription.css'

import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import CustomerService from "../../../shared/components/CustomerService/CustomerService";


const Subscription = props => {

    const subscriptionCardInformation = [
        {
            subscriptionType: "try",
            subscriptionPrice: "3.49",
            subscriptionDescription: "Trial period of 21 days",
            subscriptionTrialInfo: "",
        subscriptionTrialPrice : "",
            subscriptionButtonText: "Try Now",
            
        },
        {
            subscriptionType: "month",
            subscriptionPrice: "41.27",
            subscriptionTrialInfo: "",
        subscriptionTrialPrice : "",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy",
            
        }
    ]

    
    return (
        <section className="Subscription__main-container">
            <div className="Subscription__title-container">
                <h1 className="Subscription__title">How would you like to subscribe?</h1>
            </div>
            <div className="Subscription__card-container">
                <div className="Subscription__card">
                    {
                        subscriptionCardInformation.map((subscriptionCard, index) => (
                            <SubscriptionCard key={index} props={subscriptionCard} />
                        ))
                    }
                </div>
            </div>
            <div className="Subscription__customer-service-container">
                <div  className="Subscription__customer-service">
                    <CustomerService></CustomerService>
                </div>
            </div>
        </section>
    );
};

export default Subscription;