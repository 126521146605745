import React from "react";
import './MembershipTerms.css'

import CustomerService from '../../../shared/components/CustomerService/CustomerService';

const MembershipTerms = props => {


    return (
        <>
            <section className="Membership__main-section-container">
                <div>
                    <h1 className="text-black text-center Membership__main-title"> Terms of Membership</h1>
                </div>
                <div className="Membership__section-description">
                    <p>
                    The following is the Membership Agreement between the Provider of the Membership Service (We and Us) and the enrolled member of the Membership Service (You). 
                    <b>YOU ARE URGED TO READ THIS MEMBERSHIP AGREEMENT CAREFULLY AND. IF YOU HAVE ANY QUESTIONS, CALL OUR CUSTOMER SERVICE REPRESENTATIVES AT THE NUMBER LISTED IN THIS MEMBERSHIP INFORMATION.</b>
                    </p>
                    <p>
                        <span className="Membership__title">1. Program Offer</span> <br/>
                        By placing your order, you are enrolling in the Club Save program and you will be entitled to access to all the money saving benefits available to Program
                        members during the period of 21 days from the date of your sign up and will be charged a $3.49 non-refundable Introductory Processing Fee, which
                        covers your introductory membership period and cost of shipping and handling for your fulfillment kit/membership materials. Your Monthly Membership
                        Fee will automatically be charged to the source authorized by you at the end of the introductory period. If you decide to cancel within the introductory 
                        period, simply call the toll-free number listed on the membership information and welcome letter and you will owe only the Introductory Processing Fee.
                    </p>
                    <p>
                        <span className="Membership__title">2. Membership Benefits</span> <br/>
                        As a Member you are entitled access to discounts and/or benefits on certain products and services offered by participating vendors (“Benefits”) as 
                        explained in your Membership Materials or on the program website.
                    </p>
                    <p>
                        <span className="Membership__title">3. Membership Term</span> <br/>
                        Upon expiration of your introductory period, your membership will automatically be extended month to month ongoing basis and the Monthly 
                        Membership Fee of $41.27 per month will automatically be billed to the designated credit card or other billing source authorized by You at the time you
                        enrolled. Your membership will continue unless You notify Us that You wish to terminate this Agreement and cancel your Membership by the following 
                        instructions in paragraph 12. Your membership will be renewed automatically each month and You will be charged the then current Monthly Membership 
                        Fee which will appear on your credit/debit card statement. This is known as a Continuity Plan. We reserve the right to increase or decrease the Monthly
                        Renewal Fee for each new Membership Term.
                    </p>

                    <p>
                        <span className="Membership__title">4. Payment of Membership Fee</span> <br/>
                        The payment of your Introductory Processing Fee or Monthly Membership Fee (and, if applicable, any shipping and handling charges) is made 
                        automatically by a direct charge(s) to the billing source authorized by you in accordance with the payment. Terms to which you agreed. If applicable, in the
                        event that your billing source cannot process the Monthly Membership Fee due to insufficient available credit or funds, we may at our discretion, divide 
                        the Monthly Membership Fee into incremental charges in order to process the total Monthly Membership Fee.
                    </p>

                    <p>
                        <span className="Membership__title">5. Use of Membership</span> <br/>
                        Your Membership is non-transferable. You agree that only You and your immediate family may use the Membership. ’Immediate Family’ means You, you
                        spouse or partner and your children living at your home. Membership Benefits are not for resale. You will promptly notify us if You become aware of any 
                        unauthorized use of your Membership login info or if your Membership login info is lost or stolen. In offers that include the opportunity to claim a free 
                        gift, You must be an active member of the Program at the time your claim for the gift is processed.
                    </p>

                    <p>
                        <span className="Membership__title">6. REFUND POLICY </span> <br/>
                        WE WILL NOT REFUND YOUR INTRODUCTORY PROCESSING FEE OR ANY MONTHLY MEMBERSHIP FEES WHERE SERVICE WAS AVAILABLE FOR USE.
                        IF YOU CHOOSE TO CANCEL YOUR MEMBERSHIP THEN YOU WILL NOT BE BILLED FOR ANY MONTHLY FEE GOING FORWARD.
                    </p>

                    <p>
                        <span className="Membership__title">7. Disclaimer of Liability </span> <br/>
                        You agree ·that We and our subsidiaries and affiliates are not responsible or liable for any Benefits provided by participating vendors and, if You have any
                        claims relating to such Benefits, You will make your claim against the vendors providing the Benefit. <b>WE MAKE NO WARRANTIES EXPRESSED OR 
                        IMPLIED, INCLUDING THE WARRANTY OF Merchantability OR FITNESS FOR APARTICULAR PURPOSE WITH RESPECT TO ANY OF THE BENEFITS
                        OR RELATED INFORMATION PROVIDED TO YOU. UNDER NO CIRCUMSTANCES SHALL OUR LIABILITY EXCEED YOUR CURRENT MONTHLY 
                        MEMBERSHIP FEE AND UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR YOUR INCIDENTAL OR CONSEQUENTIAL DAMAGES. 
                        </b>
                        We reserve the right to eliminate, add, change and substitute Benefits and participating vendors without notice to You. We assume no responsibility for the 
                        payment of or contribution to any sales tax on the Benefits which may be imposed by taxing authorities and such taxes, to the extent imposed, shall 
                        remain your sole responsibility or that of the provider of the Benefits, whichever the case may be. This site is provided on an <b>‘AS IS’ AND ‘AS AVAILABLE’
                        BASIS. WE MAKE NO WARRANTIES THAT THIS SITE WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO WE MAKE ANY WARRANTIES AS TO
                        THE RESULT THAT MAY BE OBTAINED FROM THE USE OF THIS SITE OR AS TO THE ACCURACY, RELIABILITY, OR CURRENCY OF ANY 
                        INFORMATION, CONTENT, SERVICE, MERCHANDISE, OR BENEFITS PROVIDED THROUGH THE MEMBERSHIP PROGRAM. ANY MATERIAL
                        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN RISK AND YOU WILL BE SOLELY 
                        RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH 
                        MATERIAL.
                        </b>
                    </p>

                    <p>
                        <span className="Membership__title">8. Trademarks</span> <br/>
                        All trademarks, product names and company names and logos appearing on this website are the property of their respective owners.

                    </p>

                    <p>
                        <span className="Membership__title">9. Availability Restrictions</span> <br/>
                        This program is only available to residents of the contiguous United States.
                    </p>

                    <p>
                        <span className="Membership__title">10. Entire Agreement</span> <br/>
                        This Agreement contains all of the Terms of Membership and no representations, inducements, promises or agreement concerning the Membership not 
                        included in this Agreement shall be effective or enforceable. If any of the terms of this Agreement shall become invalid or unenforceable, the remaining 
                        terms shall remain in full force and effect.
                    </p>

                    <p>
                        <span className="Membership__title">10. Entire Agreement</span> <br/>
                        This Agreement contains all of the Terms of Membership and no representations, inducements, promises or agreement concerning the Membership not 
                        included in this Agreement shall be effective or enforceable. If any of the terms of this Agreement shall become invalid or unenforceable, the remaining 
                        terms shall remain in full force and effect.
                    </p>


                    <p>
                        <span className="Membership__title"><b>11. GOVERNING LAW</b></span> <br/>
                        <b>
                        THIS AGREEMENT AND THE TERMS OF MEMBERSHIP SHALL BE GOVERNED AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE
                        STATE OF FLORIDA WITHOUT GIVING EFFECT TO THE CHOICE OF LAW PROVISIONS THERE OF. ANY DISPUTE ARISING BETWEEN YOU AND 
                        US WILL BE RESOLVED BY SUBMISSION TO ARBITRATION IN FLORIDA IN ACCORDANCE WITH THE RULES OF THE AMERICAN ARBITRATION
                        ASSOCIATION IN EFFECT. NOTWITHSTANDING THE FOREGOING. NOTHING IN THIS AGREEMENT IS INTENDED OR SHALL BE CONSTRUED 
                        TO NEGATE OR OTHERWISE AFFECT THE CONSUMER PROTECTION LAWS OF THE STATE IN WHICH YOU RESIDE.
                        </b>
                    </p>

                    <p>
                        <span className="Membership__title"><b>12. TERMINATION OF MEMBERSHIP</b></span> <br/>
                        <b>
                        YOU MAY TERMINATE THIS AGREEMENT AND YOUR MEMBERSHIP AT ANY TIME BY CALLING THE TOLL-FREE NUMBER AT (833) 851 1369. 
                        YOUR CANCELLATION WILL BE EFFECTIVE PROMPTLY UPON THE RECEIPT OF YOUR CANCELLATION REQUEST. UPON CANCELLATION YOU  
                        WILL NOT OWE ANY FURTHER FEES AND WILL NOT BE ENTITLED TO A REFUND OF PAST FEES CHARGED TO YOUR ACCOUNT. ANY FEES OR
                        CHARGES INCURRED IN CONNECTION WITH THE PROGRAM, (INCLUDING BANK OR OVERDRAFT CHARGES) ARE YOUR RESPONSIBILITY. WE
                        RESERVE THE RIGHT TO TERMINATE YOUR MEMBERSHIP AT ANY TIME UPON WRITTEN NOTICE TO YOU.
                        </b>
                    </p>

                    <p>
                        Available merchants and other providers are subject to change. Listed merchants and vendors are neither sponsors, cosponsors nor affiliated with Club
                        Save. Listed merchants and vendors do not have direct relationships with users of this program and are not responsible for paying of providing any
                        incentives directly to users for making purchases. Such providing of Incentives and rebates are the sole responsibility of Exclusive Incentive Marketing, a 
                        leading provider of Premiums, employee and other member recognition programs.
                    </p>


                </div>
            </section>

            <section className="MembershipTerms__customer-service-container">
                <div className="MembershipTerms__customer-service">
                    <CustomerService></CustomerService>
                </div>
            </section>

        </>
    );
};

export default MembershipTerms;