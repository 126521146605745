import React from "react";

import privacyPolicyLegend from '../../utils/assets/privacyPolicy/privacy-policy.png'; 
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import Policy from '../components/Policy/Policy';
import InformationNav from "../../shared/components/InformationNav/InformationNav";




const PrivacyPolicy = props => {
    const legendInfo =
    {
        cardTitle: "Learn how we protect your information",
        cardBodyText: `At Club Save, we take the protection of your personal information very seriously.`,
        cardButtonText: "Call us",
        lengendBackground: privacyPolicyLegend
    }

    return (
        <React.Fragment>
         
            <SectionInfo
                props={legendInfo}
            />
            <InformationNav props={"Privacy policy"}></InformationNav>
            
            <Policy />
       
            
           
            
        </React.Fragment>
    );
};

export default PrivacyPolicy;