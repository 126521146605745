import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MainHeader from './MainHeader';
import NavLinks from './NavLinks';
import SideDrawer from './SideDrawer';
import Backdrop from '../UIElements/Backdrop';


import './MainNavigation.css';


import MainLogo from '../../assets/HeaderAssets/main-logo.png';
import FacebookIcon from '../../assets/HeaderAssets/facebook-icon.png';
import InstagramIcon from '../../assets/HeaderAssets/instagram-icon.png';


const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const setScreen = () =>{
    window.scroll(0,0);
  }

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>

      <MainHeader>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <h1 className="main-navigation__title">
          <img src={MainLogo} className='MainNavigation__logo' alt='Club Save logo' onClick={setScreen}></img>
        </h1>
        <nav className="main-navigation__header-nav">
          <NavLinks />
        </nav>
        <div className='MainNavigation__main-social-media-container'>
          <div className='MainNavigation__inner-social-media-container'>
            <a href='https://www.instagram.com/club.save/' target='_blank'><img src={InstagramIcon} alt='instagram-icon' style={{marginRight : '.3em'}}></img></a>
            <a href='https://www.facebook.com/club.savellc' target='_blank' ><img src={FacebookIcon} alt='facebook-icon' style={{marginRight : '.3em'}}></img></a>
          </div>
        </div>


      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
