
 const localStorageService = {

    saveProduct : ( key, product )=>{
        sessionStorage.setItem(key, JSON.stringify( product ));
    },


    getProduct : ( key )=>{
        return JSON.parse(sessionStorage.getItem(key));
    },

    deleteProduct : ( key )=>{
        sessionStorage.removeItem( key );
    },

    deleteAcceptanceOfTermsAndServices : ( key ) =>{
        sessionStorage.removeItem( key )
    }

}




export default localStorageService;
